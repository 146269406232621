import Flickity from 'flickity';
import 'viewerjs/dist/viewer.min.js';
import 'jquery-viewer/dist/jquery-viewer.min.js';
import 'select2/dist/js/select2.min.js';
import AOS from 'aos';
import 'flickity/dist/flickity.pkgd.js';

// sticky header variable
var shrinkHeader = 100;

Flickity.prototype._createResizeClass = function() {
  this.element.classList.add('flickity-resize');
};

Flickity.createMethods.push('_createResizeClass');

var resize = Flickity.prototype.resize;
Flickity.prototype.resize = function() {
  this.element.classList.remove('flickity-resize');
  resize.call( this );
  this.element.classList.add('flickity-resize');
};

export default {
  init() {
    // JavaScript to be fired on all pages

    // Flickity.defaults.dragThreshold = 3;
  },
  finalize() {

    AOS.init();

    // JavaScript to be fired on all pages, after page specific JS is fired

    let mapsSectionWidth = checkMapWidth();

    if ( mapsSectionWidth == true) {
      $('#map-address-wrp').addClass('flickity-now');
      mapSectionSlider()
    }

    else {
      mapSectionSliderDestroy()
    }

    let wpcf7Element = document.querySelector('#motor-calculator-section .calculator-contact-form-wrp .wpcf7');

    if (wpcf7Element) {
      wpcf7Element.addEventListener( 'wpcf7invalid', function(event) {
        event.preventDefault();
        $('#motor-calculator-section').addClass('invalid-form');
      }, false );

      wpcf7Element.addEventListener( 'wpcf7mailsent', function(event) {
        event.preventDefault();
        $('#motor-calculator-section').removeClass('invalid-form');
      }, false );
    }

    // Add Apllications for Motor Calculations
    let wpcf7AccordionForm = document.querySelector('.accordion-form-section .wpcf7');

    if (wpcf7AccordionForm) {
      wpcf7AccordionForm.addEventListener( 'wpcf7invalid', function(event) {
        event.preventDefault();

        $('.accordion-form-section .accordion-item-wrp').each(function() {
          $(this).find('.faq-title').addClass('collapsed');
          $(this).find('.faq-content').removeClass('show');
        });

        $('.accordion-form-section .accordion-item-wrp').first().find('.faq-title').removeClass('collapsed');
        $('.accordion-form-section .accordion-item-wrp').first().find('.faq-content').addClass('show');
      }, false );
    }
    // Add Apllications for Motor Calculations

    // Add Apllications for Motor Calculations
    if ($('body').hasClass('template-calculator')) {
      $('#select-motor-application').select2({
        dropdownParent: $('.select2-wrapper'),
      });
      motorCalculator();

      $('#select-motor-application').on('change', function() {
        motorCalculator();
      });
    }
    // Add Apllications for Motor Calculations

    // Listen for Input Number Field Changes on Calculator Page and Run Calculations, Validations
    $('#motor-calculator-section input').not('#motor-calculator-section .calculator-contact-form-wrp input , #motor-calculator-section .radio-wrp input').on('keyup change', function() {
      motorCalculator();
      $(this).valid();
    });

    // Listen for Radio Motor Changes on Calculator Page and Run Calculations without Validations
    $('#motor-calculator-section .radio-wrp input').on('keyup change', function() {
      motorCalculator();
    });

    // Listen for Input Changes on Product Listing Page and Run Calculations, Validations
    $('.motor-items-wrp .motor-item input').on('keyup change', function() {
      let calculatorSection = $(this).parents('.motor-item');
      motorListingCalculator(calculatorSection);
      // $(this).valid();
    });

    // Run Calculations in Calculations Page on Page Load
    $('.motor-items-wrp .motor-item').each(function() {
      let test = $(this);
      motorListingCalculator(test);
    });

    // Calculations Page Proceed Button Click
    $('#calculator-proceed-button').click(function(e){
      e.preventDefault();

      $('#motor-calculator-section').removeClass('step-1');
      $('#motor-calculator-section').addClass('step-2');
      $('#motor-selection').css({'display':'none'});
      let selectedMotor = $('input[name="motor-select"]:checked').val();
      let selectedMotorSeperated = selectedMotor.split('-');
      let selectedMotorId = parseFloat(selectedMotorSeperated[1]);

      $('.motor-details-wrp .form-motor-item-wrp').each(function() {
        let imageWrp = $(this).find('.img-wrp');
        if ($(this).is(`#motor-${selectedMotorId}`)) {
          $(this).fadeIn(500);
          $('.change-btn-wrp').appendTo(imageWrp);
          $('.change-btn-wrp').removeClass('d-none');
        }
      });

      $('.calculator-inner-wrp').css({'display':'none'});
      $('.calculator-form-wrp').fadeIn(500);
    });

    // Calculations Page Goto Edit Button CLick
    $('#goto-edit-calculator').click(function(e){
      e.preventDefault();

      $('#motor-calculator-section').addClass('step-1');
      $('#motor-calculator-section').removeClass('step-2');
      $('.calculator-page .wpcf7-form-control-wrap').children('.wpcf7-not-valid-tip').css('display', 'none');
      $('.calculator-contact-form-wrp').find('.wpcf7-response-output').css('display', 'none');

      $('.motor-details-wrp .form-motor-item-wrp').each(function() {
        $(this).css('display', 'none');
      });

      $('#motor-selection').fadeIn(500);

      $('.calculator-inner-wrp').fadeIn(500);
      $('.calculator-form-wrp').css({'display':'none'})
    });

    // Calculations Page CF7 On Mail Sent Function
    if (document.querySelector( '.calculator-contact-form-wrp .wpcf7' )) {
      var wpcf7Elm = document.querySelector( '.calculator-contact-form-wrp .wpcf7');

      wpcf7Elm.addEventListener( 'wpcf7submit', function() {
        $('.calculator-page .wpcf7-form-control-wrap').children('.wpcf7-not-valid-tip').css('display','block');
        $('.calculator-contact-form-wrp').find('.wpcf7-response-output').css('display', 'block');
      }, false );

      wpcf7Elm.addEventListener( 'wpcf7mailsent', function() {
        let selectedMotor = $('input[name="motor-select"]:checked').val();
        let selectedMotorSeperated = selectedMotor.split('-');
        let selectedMotorId = parseFloat(selectedMotorSeperated[1]);

        $('.motor-datasheets-wrp .motor-datasheet-item').each(function() {
          if ($(this).is(`#motor-${selectedMotorId}`)) {
            // $(this).removeClass('d-none');
            $(this).fadeIn(500);
          }
        });

        $('#motor-calculator-section .calculator-form-wrp').css({'display':'none'})
        $('.motor-details-wrp .form-motor-item-wrp').each(function() {
          // $(this).addClass('d-none');
          $(this).css({'display':'none'});
        });
      }, false );
    }

    // Insights & News Load More
    $(document).ready(function () {
      $('.insights-item-v1').slice(0, 1).show();
      $('.insights-item-v2').slice(0, 1).show();
      if ($('.insights-item-v1:hidden').length != 0 || $('.insights-item-v2:hidden').length != 0) {
        $('#loadMoreInsights').show();
      } else {
        $('#loadMoreInsights').hide();
        $('#loadMoreInsights').text('').fadeOut('slow');
        $('#loadMoreInsights').addClass('load-more-end');
      }
      $('#loadMoreInsights').on('click', function (e) {
        e.preventDefault();
        $('.insights-item-v1:hidden').slice(0, 1).slideDown();
        $('.insights-item-v2:hidden').slice(0, 1).slideDown();
        if ($('.insights-item-v1:hidden').length == 0) {
          $('#loadMoreInsights').text('No More to view').fadeOut('slow');
          $('#loadMoreInsights').addClass('load-more-end');
        }
      });
    });

    //map-section popup+
    $('.contact-map-section #map-address-wrp .title-wrp-outer').each(function () {
      $(this).click(function() {
        $('.map-details').slideUp();
        $(this).next('.map-details').slideDown();
      });
    });

    $('.contact-map-section .flickity-button').click(function () {
      $('.show-address .map-details').slideUp();
      $('.item-wrp').each(function () {
        $(this).removeClass('show-address');
      });
    });
    //map-section popup

    // Product Listing Button Action
    $('.product-listing-view-datasheet').click(function (e) {
      e.preventDefault();
      $(this).slideUp();
      $(this).siblings('.product-listing-view-datasheet-content').slideDown();
    });

    var wpcf7ElmListing = document.querySelectorAll( '.products-listing-section .motor-items-wrp .wpcf7' );

    for (let i = 0; i <wpcf7ElmListing.length; i++) {
      wpcf7ElmListing[i].addEventListener( 'wpcf7mailsent', function() {
        window.open($(this).parent().siblings('.datasheet-link').attr('href'));
      }, false );
    }
    // Product Listing Button Action

    // Insights & News Mobile Load More
    $(document).ready(function () {
      $('.insights-item-mobile').slice(0, 2).show();
      if ($('.insights-item-mobile:hidden').length != 0) {
        $('#loadMoreInsightsMobile').show();
      } else {
        $('#loadMoreInsightsMobile').hide();
        $('#loadMoreInsightsMobile').text('').fadeOut('slow');
        $('#loadMoreInsightsMobile').addClass('load-more-end');
      }
      $('#loadMoreInsightsMobile').on('click', function (e) {
        e.preventDefault();
        $('.insights-item-mobile:hidden').slice(0, 2).slideDown();
        if ($('.insights-item-mobile:hidden').length == 0) {
          $('#loadMoreInsightsMobile').text('No More to view').fadeOut('slow');
          $('#loadMoreInsightsMobile').addClass('load-more-end');
        }
      });
    });

    // toggle class on hamburger icon click
    $('.mobile-burger-icon').click(function () {
      $('body, .mobile-burger-icon').toggleClass('show-menu');
    });

    // mobile sub menu navigation
    // level 1
    $('.mobile-menu .nav > li.menu-item-has-children').each(function () {
      $(this).find('> .sub-menu-wrap > .sub-menu').prepend('<div class="return-link">' + $(this).find('> a').text() + '</div>');
      $(this).find('> a').click(function (e) {
          e.preventDefault();
          $(this).siblings('.sub-menu-wrap').toggleClass('show-menu');
        });
    });

    // level 2
    $('.mobile-menu .nav > li.menu-item-has-children > .sub-menu-wrap li.menu-item-has-children').each(function () {
      $(this).find('> .sub-menu-wrap > .sub-menu').prepend('<div class="return-link">' + $(this).find('> a').text() + '</div>');
      $(this).find('> a').click(function (e) {
          e.preventDefault();
          $(this).siblings('.sub-menu-wrap').toggleClass('show-menu');
        });
    });

    // return link click
    $('.return-link').each(function () {
      $(this).click(function () {
        $(this).parent().parent('.sub-menu-wrap').removeClass('show-menu');
      });
    });

    // set header height to sticky overlay
    $('.sticky-overlay').outerHeight($('.header').outerHeight());


    // Cookie Policy Set up
    //check to see if the submited cookie is set, if not check if the popup has been closed, if not then display the popup
    if( getCookie('popupCookie') != 'submited'){
      if(getCookie('popupCookie') != 'closed' ){
        setTimeout(function(){$('.cookie-wrp').css('display', 'block').hide().fadeIn();}, 3000);
      }
    }

    $('a.close-cookie').click(function(){
      $('.cookie-wrp').fadeOut();
      //sets the coookie to one minute (.00069444444) if the popup is closed (whole numbers = days)
      setCookie( 'popupCookie', 'closed', 1 );
    });

    $('a.accept-cookie').click(function(){
      $('.cookie-wrp').fadeOut();
      //sets the coookie to five minutes (.0034722222 ) if the popup is submited (whole numbers = days)
      setCookie( 'popupCookie', 'submited', 100);
    });
    // Cookie Policy Set up

    newCalculator();
    motorCalculator();
    formScripts();
    cardSectionGridSlider();
    cardSectionSlider();
    cardSectionTimeline();
    gallerySectionSlider();
    jqueryViewer('#gallery-images-viewer-v2');
    jqueryViewer('#gallery-images-viewer-v1');
    fullWidthVideoPlayer();
    videoPopupPlayer();
    delayHomeBannerVideo();
    slideUpMenuOption();
    footerTicker();
    productSectionGridSlider();
    productSectionSlider();
    checkSectionsForGaps();
    productListingItem();
    testimonialsSlider()
  },
};

$(window).on('resize', function () {
  let mapSectionWidthResize = checkMapWidth();

  if ( mapSectionWidthResize == true) {
    $('#map-address-wrp').addClass('flickity-now');
    mapSectionSlider()
  }

  $('.fcp-testimonials-section .testimonials-wrp, .cards-section.v1 .cards-wrp, .cards-section.v2 .cards-wrp, .cards-section.v3 .cards-wrp, .products-section.v1 .products-wrp, .products-section.v2 .products-wrp, .gallery-section.v1 .gallery-wrapper, .products-listing-section .product-carousel-nav, .products-listing-section .product-nav').flickity('resize');
});

$(window).on('load', function () {
  $('.fcp-testimonials-section .testimonials-wrp, .cards-section.v1 .cards-wrp, .cards-section.v2 .cards-wrp, .cards-section.v3 .cards-wrp, .products-section.v1 .products-wrp, .products-section.v2 .products-wrp, .gallery-section.v1 .gallery-wrapper, .products-listing-section .product-carousel-nav, .products-listing-section .product-nav',).flickity('resize');
});

//map slider
function mapSectionSlider() {
  var cellAlignValue = '';

  if (matchMedia('screen and (max-width: 768px)').matches) {
    cellAlignValue = 'center';
  } else {
    cellAlignValue = 'left';
  }

  $(
    '.contact-map-section .main-carousel.flickity-now'
  ).flickity({
    cellAlign: cellAlignValue,
    pageDots: false,
    prevNextButtons: true,
    wrapAround: true,
    initialIndex: 2,
  });
}

function mapSectionSliderDestroy() {
  $('.contact-map-section .main-carousel.flickity-now').flickity('destroy');
}

// sticky menu
$(window).scroll(function () {
  var scroll = getCurrentScroll();
  if (scroll >= shrinkHeader) {
    $('body, .header-container').addClass('sticky-header');
  } else {
    $('body, .header-container').removeClass('sticky-header');
  }

  $('.sticky-overlay').outerHeight($('.header').outerHeight());
});

function getCurrentScroll() {
  return window.pageYOffset || document.documentElement.scrollTop;
}
// sticky menu

function formScripts() {
  $('.form-dropdown').select2({
    dropdownParent: $('.select2-wrapper'),
  });

  $('.button-wrp-outter').append($('.wpcf7-response-output'));
}

function cardSectionGridSlider() {
  $('.cards-section.v1 .section-inner-wrp .cards-wrp').flickity({
    pageDots: true,
    prevNextButtons: false,
    wrapAround: true,
    watchCSS: true,
  });
}

function cardSectionSlider() {
    $('.cards-section.v2').each(function() {
      var cellAlignValue = '';

      if (matchMedia('screen and (max-width: 768px)').matches) {
        cellAlignValue = 'center';
      } else {
        cellAlignValue = 'left';
      }

      $(this).find('.cards-wrp').flickity({
        pageDots: true,
        prevNextButtons: true,
        wrapAround: true,
        cellAlign: cellAlignValue,
      });

    $(this).find('.flickity-page-dots').appendTo($(this).find('.pagination-wrp'));
    $(this).find('.flickity-button').appendTo($(this).find('.pagination-wrp'));
  });
}

function cardSectionTimeline() {
  $('.cards-section.v3').each(function() {
    var cellAlignValue = '';

    if (matchMedia('screen and (max-width: 768px)').matches) {
      cellAlignValue = 'center';
    } else {
      cellAlignValue = 'left';
    }

    $(this).find('.cards-wrp').flickity({
      pageDots: true,
      prevNextButtons: true,
      wrapAround: false,
      cellAlign: cellAlignValue,
      groupCells: 1,
      contain: true,
    });

    $(this).find('.flickity-page-dots').appendTo($(this).find('.pagination-wrp'));
    $(this).find('.flickity-button').appendTo($(this).find('.pagination-wrp'));
  });
}

function productSectionGridSlider() {
  $('.products-section.v1 .section-inner-wrp .products-wrp').flickity({
    pageDots: true,
    prevNextButtons: false,
    wrapAround: true,
    watchCSS: true,
  });
}

function productSectionSlider() {
  $('.products-section.v2').each(function() {
    var cellAlignValue = '';

    if (matchMedia('screen and (max-width: 768px)').matches) {
      cellAlignValue = 'center';
    } else {
      cellAlignValue = 'left';
    }

    $(this).find('.products-wrp').flickity({
      pageDots: true,
      prevNextButtons: true,
      wrapAround: true,
      cellAlign: cellAlignValue,
    });

    $(this).find('.flickity-page-dots').appendTo($(this).find('.pagination-wrp'));
    $(this).find('.flickity-button').appendTo($(this).find('.pagination-wrp'));
  });
}

function testimonialsSlider() {
  $('.fcp-testimonials-section').each(function() {
    $('.fcp-testimonials-section .testimonials-wrp').flickity({
      pageDots: true,
      prevNextButtons: true,
      wrapAround: true,
      cellAlign: 'center',
      adaptiveHeight: true,
    });

    $('.fcp-testimonials-section .testimonials-wrp').find('.flickity-button').appendTo($('.fcp-testimonials-section .testimonials-wrp-outer').find('.flickity-wrp'));
  });
}


function gallerySectionSlider() {
  $('.gallery-section.v1').each(function() {
    var cellAlignValue = '';

    if (matchMedia('screen and (max-width: 768px)').matches) {
      cellAlignValue = 'center';
    } else {
      cellAlignValue = 'left';
    }

    $(this).find('.gallery-wrp-slider').flickity({
      pageDots: true,
      prevNextButtons: true,
      wrapAround: true,
      cellAlign: cellAlignValue,
    });

    $(this).find('.flickity-page-dots').appendTo($(this).find('.pagination-wrp'));
    $(this).find('.flickity-button').appendTo($(this).find('.pagination-wrp'));
  });
}

function productListingItem() {

  var counter = 0;
  $('.products-listing-section .sliders-wrp').each(function(){
    var productNavClassName = '.product-nav-'+ counter;
    var mainCarousel = $(this).find(productNavClassName);
    var asNavCarousel =  $(this).find('.product-carousel-nav-'+ counter);

    // 1st carousel, main
    $(mainCarousel).flickity({
      pageDots: true,
      prevNextButtons: true,
      wrapAround: true,
    });

    // 2nd carousel, navigation
    $(asNavCarousel).flickity({
      asNavFor: productNavClassName,
      contain: true,
      pageDots: false,
      prevNextButtons: false,
    });
    counter++;
  });
}

// Implementation of Jquery Viewer
function jqueryViewer(ulId) {
  var $galleryImages = $(ulId);

  $galleryImages.viewer({
    tooltip: 0,
    inline: false,
    toolbar: {
      zoomIn: {
        show: 1,
        size: 'large',
      },
      zoomOut: {
        show: 1,
        size: 'large',
      },
      oneToOne: 0,
      reset: 0,
      prev: {
        show: 1,
        size: 'large',
      },
      play: {
        show: 0,
        size: 'large',
      },
      next: {
        show: 1,
        size: 'large',
      },
      rotateLeft: 0,
      rotateRight: 0,
      flipHorizontal: 0,
      flipVertical: 0,
    },
    title: 0,
    viewed: function () {
      $galleryImages.viewer('zoomTo', 1);
    },
  });
}
// Implementation of Jquery Viewer

// Full Width Video Player
function fullWidthVideoPlayer() {
  for (let i = 1; i <= $('.playvideo').length ; i++) {
    let idName = 'play-video-' + i;
    let newIdName = 'video-iframe-' + i;
    $('.playvideo')[i-1].setAttribute('id', idName);
    $('.iframe-video')[i-1].setAttribute('id', newIdName);
    $('#'+idName).click(function(){
      $(this).parent('.video-wrp').addClass('video-playing');
      if ($('#'+newIdName).hasClass('video-element')) {
        $('#'+ newIdName).css('opacity','1');
        $('#'+ newIdName).get(0).play();
        $('#' + idName).css({
          'opacity' : '0',
          'z-index' : '-1',
        });
      } else {
        $('#'+ newIdName).css('opacity','1');
        $('#'+ newIdName)[0].src += '&autoplay=1';
        $('#' + idName).css({
          'opacity' : '0',
          'z-index' : '-1',
        });
      }
    });
  }
}
// Full Width Video Player

// Popup Video Player for Two Column Section
function videoPopupPlayer() {
  var $videoSrc;
  $('.video-iframe-thumbnail').click(function () {
    $videoSrc = $(this).data('src');
  });

  $('#myModal').on('shown.bs.modal', function () {
    $('#video').attr(
      'src',
      $videoSrc + '?autoplay=1&mute=1&amp;modestbranding=1&amp;showinfo=0'
    );
  });

  $('#myModal').on('hide.bs.modal', function () {
    $('#video').attr('src', $videoSrc);
  });
}
// Popup Video Player for Two Column Section

// Delay Home Banner Video
function delayHomeBannerVideo() {
  setTimeout(function() {
    $('#home-banner-iframe').attr('src',$('#hidden-iframe-url').text());
    $('#home-banner-video').attr('src',$('#hidden-video-file').text());
  }, 3000);
}
// Delay Home Banner Video

function footerTicker() {
  if ($('.footer .email-ticker-wrp')) {
    // Play with this value to change the speed
    let tickerSpeed = 1;

    let flickity = null;
    let isPaused = false;
    const slideshowEl = document.querySelector('.footer .email-ticker-wrp');

    const update = () => {
      if (isPaused) return;
      if (flickity.slides) {
        flickity.x = (flickity.x - tickerSpeed) % flickity.slideableWidth;
        flickity.selectedIndex = flickity.dragEndRestingSelect();
        flickity.updateSelectedSlide();
        flickity.settle(flickity.x);
      }
      window.requestAnimationFrame(update);
    };

    const pause = () => {
      isPaused = true;
    };

    const play = () => {
      if (isPaused) {
        isPaused = false;
        window.requestAnimationFrame(update);
      }
    };

    flickity = new Flickity(slideshowEl, {
      autoPlay: false,
      prevNextButtons: false,
      pageDots: false,
      draggable: true,
      wrapAround: true,
      selectedAttraction: 0.015,
      friction: 0.25,
    });

    flickity.x = 0;

    slideshowEl.addEventListener('mouseenter', pause, false);
    slideshowEl.addEventListener('focusin', pause, false);
    slideshowEl.addEventListener('mouseleave', play, false);
    slideshowEl.addEventListener('focusout', play, false);

    flickity.on('dragStart', () => {
      isPaused = true;
    });

    update();
  }
}

// Header Scroll up Option
function slideUpMenuOption() {
  if ($('body > .header').hasClass('enable-scroll-up')) {
    var lastScrollTop = 0, delta = 15;
    var bannerHeight = $('.header').outerHeight();
    $(window).scroll(function(){
      var st = $(this).scrollTop();

      if(Math.abs(lastScrollTop - st) <= delta)
        return;
      if ((st > lastScrollTop) && (lastScrollTop>0)) {
            // downscroll code
            $('body > .header, body > .sticky-overlay').css('top', (bannerHeight - (bannerHeight *2)));
      } else {
        // upscroll code
        $('body > .header, body > .sticky-overlay').css('top','0px');
      }
      lastScrollTop = st;
    });
  }
}
// Header Scroll up Option

function motorCalculator() {
  if ($('input[name="motor-select"]:checked').val()) {
    // Get Selected Motor Details
    let motorRadio = $('input[name="motor-select"]:checked').val();
    let motorValues = motorRadio.split('-');
    let motorId = parseFloat(motorValues[1]);
    let motorName = motorValues[2];

    $('#form-motor-name').text(motorName);

    // Get Input Values and Check for Validation
    let frontalArea = parseFloat($('.motor-calculation .car-frontal-area').val());
    if (frontalArea < 0) {
      $('.motor-calculation .car-frontal-area').val('2.24');
      $('.motor-calculation .car-frontal-area').trigger('change');
    }
    let mass = parseFloat($('.motor-calculation .mass-of-car').val());
    if (mass <= 0) {
      $('.motor-calculation .mass-of-car').val('1635');
      $('.motor-calculation .mass-of-car').trigger('change');
    }
    let gearRatio = parseFloat($('.motor-calculation .gear-ratio').val());
    if (gearRatio < 1) {
      $('.motor-calculation .gear-ratio').val('3');
      $('.motor-calculation .gear-ratio').trigger('change');
    }
    let tyreRadius = parseFloat($('.motor-calculation .tyre-radius').val());
    if (tyreRadius < 0) {
      $('.motor-calculation .tyre-radius').val('0.2515');
      $('.motor-calculation .tyre-radius').trigger('change');
    }
    let airDensity = parseFloat($('.motor-calculation .air-density').val());
    if (airDensity < 0 || airDensity > 1.5) {
      $('.motor-calculation .air-density').val('1.225');
      $('.motor-calculation .air-density').trigger('change');
    }
    let transmissionEfficiency = parseFloat($('.motor-calculation .transmission-efficiency').val());
    if (transmissionEfficiency < 1 || transmissionEfficiency > 99) {
      $('.motor-calculation .transmission-efficiency').val('96');
      $('.motor-calculation .transmission-efficiency').trigger('change');
    }
    let rollingResistance = parseFloat($('.motor-calculation .rolling-resistance').val());
    if (rollingResistance < 0) {
      $('.motor-calculation .rolling-resistance').val('0.0054');
      $('.motor-calculation .rolling-resistance').trigger('change');
    }
    let dragCoefficient = parseFloat($('.motor-calculation .coefficient-of-drag').val());
    if (dragCoefficient < 0) {
      $('.motor-calculation .coefficient-of-drag').val('0.24');
      $('.motor-calculation .coefficient-of-drag').trigger('change');
    }
    let angleOfSlope = parseFloat($('.motor-calculation .angle-of-slope').val());
    if (angleOfSlope < -89 || angleOfSlope > 89) {
      $('.motor-calculation .angle-of-slope').val('1');
      $('.motor-calculation .angle-of-slope').trigger('change');
    }
    let gravity = 9.81;
    let effectiveMass = (mass*(1.04+0.0025*gearRatio*gearRatio)).toFixed(1);
    let myData = JSON.parse(eval(`jsonMotorData${motorId}`));

    // Set Motor Data to Contact Form
    $('#form-selected-motor').val(motorName);
    $('#form-selected-car-frontal-area').val(frontalArea);
    $('#form-selected-gear-ratio').val(gearRatio);
    $('#form-selected-mass').val(mass);
    $('#form-selected-tyre-radius').val(tyreRadius);

    // Calculation of Acceleration
    const gettorque = getTorqueForRPM(0,myData);
    const getThrust = gettorque*transmissionEfficiency*0.01*gearRatio/tyreRadius;
    const getResistanceForces = (0.5*airDensity*frontalArea*dragCoefficient*0)+(rollingResistance*effectiveMass*gravity*Math.cos(angleOfSlope*Math.PI/180))+(effectiveMass*gravity*Math.sin(angleOfSlope*Math.PI/180));
    const notChangingResistance = (rollingResistance*effectiveMass*gravity*Math.cos(angleOfSlope*Math.PI/180))+(effectiveMass*gravity*Math.sin(angleOfSlope*Math.PI/180));
    const getAcceleration = (getThrust-getResistanceForces)/(effectiveMass);

    let maxSpeedFromRPM = tyreRadius*2*3.1416*Number(myData[myData.length-1][0])/(60*gearRatio);

    let previousVelocity= 0;
    let previousAcceleration= getAcceleration;
    let newVelocity=0.02;
    let newAcceleration;
    let countTo100 = 0;
    let countToEnd = 0;

    let dontBreakLoop = true;

    while (dontBreakLoop) {
      countToEnd++;
      if (newVelocity < 27.7778) {
        countTo100++;
      }

      if (previousVelocity+previousAcceleration*0.1 < maxSpeedFromRPM) {
        newVelocity = previousVelocity+previousAcceleration*0.1;
      } else {
        dontBreakLoop = false;
      }
      newAcceleration = (((getTorqueForRPM((60*newVelocity*gearRatio/(2*3.1416*tyreRadius)),myData))*transmissionEfficiency*0.01*gearRatio/tyreRadius)-(0.5*airDensity*frontalArea*dragCoefficient*newVelocity*newVelocity + notChangingResistance))/(effectiveMass);

      if (newAcceleration < 0.001 || countToEnd >= 3000 || newVelocity-previousVelocity<0.001) {
        dontBreakLoop = false;
      }

      previousVelocity = newVelocity;
      previousAcceleration = newAcceleration;
    }

    if (newVelocity >= 27.778) {
      $('.motor-calculation .vehicle-acceleration').text(`${((countTo100-1)/10).toFixed(1)} sec`);
    } else {
      $('.motor-calculation .vehicle-acceleration').text('Cannot Reach 100km/h');
    }

    if (isNaN(newVelocity)) {
      $('.motor-calculation .max-speed').text(`${parseInt(0)} mph`);
    } else if (maxSpeedFromRPM <= newVelocity) {
      $('.motor-calculation .max-speed').text(`${parseInt(maxSpeedFromRPM*2.236936)} mph`);
    } else if (maxSpeedFromRPM > newVelocity) {
      $('.motor-calculation .max-speed').text(`${parseInt(newVelocity*2.236936)} mph`);
    }
  }
}

function motorListingCalculator(motorSectionWrp) {
  // Get Selected Motor Details
  let motorData = motorSectionWrp.attr('data-motor');
  let motorValues = motorData.split('-');
  let motorId = parseFloat(motorValues[1]);

  // Get Input Values and Check for Validation
  let frontalArea = parseFloat(motorSectionWrp.find('.car-frontal-area').val());
  if (frontalArea < 0) {
    motorSectionWrp.find('.car-frontal-area').val('2.24');
    motorSectionWrp.find('.car-frontal-area').trigger('change');
  }
  let mass = parseFloat(motorSectionWrp.find('.mass-of-car').val());
  if (mass <= 0) {
    motorSectionWrp.find('.mass-of-car').val('1635');
    motorSectionWrp.find('.mass-of-car').trigger('change');
  }
  let gearRatio = parseFloat(motorSectionWrp.find('.gear-ratio').val());
  if (gearRatio < 1) {
    motorSectionWrp.find('.gear-ratio').val('3');
    motorSectionWrp.find('.gear-ratio').trigger('change');
  }
  let tyreRadius = parseFloat(motorSectionWrp.find('.tyre-radius').val());
  if (tyreRadius < 0.0001) {
    motorSectionWrp.find('.tyre-radius').val('0.2515');
    motorSectionWrp.find('.tyre-radius').trigger('change');
  }

  let airDensity = parseFloat(motorSectionWrp.find('.air-density').val());
  if (airDensity < 0.4 || airDensity > 1.5) {
    motorSectionWrp.find('.air-density').val('1.225');
    motorSectionWrp.find('.air-density').trigger('change');
  }
  let transmissionEfficiency = parseFloat(motorSectionWrp.find('.transmission-efficiency').val());
  if (transmissionEfficiency < 1 || transmissionEfficiency > 99) {
    motorSectionWrp.find('.transmission-efficiency').val('96');
    motorSectionWrp.find('.transmission-efficiency').trigger('change');
  }
  let rollingResistance = parseFloat(motorSectionWrp.find('.rolling-resistance').val());
  if (rollingResistance < 0.0001) {
    motorSectionWrp.find('.rolling-resistance').val('0.0054');
    motorSectionWrp.find('.rolling-resistance').trigger('change');
  }
  let dragCoefficient = parseFloat(motorSectionWrp.find('.coefficient-of-drag').val());
  if (dragCoefficient < 0.01) {
    motorSectionWrp.find('.coefficient-of-drag').val('0.24');
    motorSectionWrp.find('.coefficient-of-drag').trigger('change');
  }
  let angleOfSlope = parseFloat(motorSectionWrp.find('.angle-of-slope').val());
  if (angleOfSlope < -89 || angleOfSlope > 89) {
    motorSectionWrp.find('.angle-of-slope').val('1');
    motorSectionWrp.find('.angle-of-slope').trigger('change');
  }

  let gravity = 9.81;
  let effectiveMassListing = (mass*(1.04+0.0025*gearRatio*gearRatio)).toFixed(1);
  let myDataListing = JSON.parse(eval(`jsonMotorDataListing${motorId}`));

  // Calculation of Acceleration
  const gettorque = getTorqueForRPM(0,myDataListing);
  const getThrust = gettorque*transmissionEfficiency*0.01*gearRatio/tyreRadius;
  const getResistanceForces = (0.5*airDensity*frontalArea*dragCoefficient*0)+(rollingResistance*effectiveMassListing*gravity*Math.cos(angleOfSlope*Math.PI/180))+(effectiveMassListing*gravity*Math.sin(angleOfSlope*Math.PI/180));
  const notChangingResistance = (rollingResistance*effectiveMassListing*gravity*Math.cos(angleOfSlope*Math.PI/180))+(effectiveMassListing*gravity*Math.sin(angleOfSlope*Math.PI/180));
  const getAcceleration = (getThrust-getResistanceForces)/(effectiveMassListing);

  let maxSpeedFromRPM = tyreRadius*2*3.1416*Number(myDataListing[myDataListing.length-1][0])/(60*gearRatio);

  let previousVelocity= 0;
  let previousAcceleration= getAcceleration;
  let newVelocity=0.02;
  let newAcceleration;
  let countTo100 = 0;
  let countToEnd = 0;

  let dontBreakLoop = true;

  while (dontBreakLoop) {
    countToEnd++;
    if (newVelocity < 27.7778) {
      countTo100++;
    }

    if (previousVelocity+previousAcceleration*0.1 < maxSpeedFromRPM) {
      newVelocity = previousVelocity+previousAcceleration*0.1;
    } else {
      dontBreakLoop = false;
    }
    newAcceleration = (((getTorqueForRPM((60*newVelocity*gearRatio/(2*3.1416*tyreRadius)),myDataListing))*transmissionEfficiency*0.01*gearRatio/tyreRadius)-(0.5*airDensity*frontalArea*dragCoefficient*newVelocity*newVelocity + notChangingResistance))/(effectiveMassListing);

    if (newAcceleration < 0.001 || countToEnd >= 3000 || newVelocity-previousVelocity<0.001) {
      dontBreakLoop = false;
    }

    previousVelocity = newVelocity;
    previousAcceleration = newAcceleration;
  }

  if (newVelocity >= 27.778) {
    motorSectionWrp.find('.vehicle-acceleration').text(`${((countTo100-1)/10).toFixed(1)} sec`);
  } else {
    motorSectionWrp.find('.vehicle-acceleration').text('Cannot Reach 100km/h');
  }

  if (isNaN(newVelocity)) {
    motorSectionWrp.find('.max-speed').text(`${parseInt(0)} mph`);
  } else if (maxSpeedFromRPM <= newVelocity) {
    motorSectionWrp.find('.max-speed').text(`${parseInt(maxSpeedFromRPM*2.236936)} mph`);
  } else if (maxSpeedFromRPM > newVelocity) {
    motorSectionWrp.find('.max-speed').text(`${parseInt(newVelocity*2.236936)} mph`);
  }
}

function checkSectionsForGaps() {
  $('.fcp-section.bg-enabled, .fcp-section.with-image, .fcp-section.image-section.v1, .fcp-section.video-section.v1, .fcp-section.cta-section').each(function () {
    if($(this).next().hasClass('bg-enabled')) {
      $(this).addClass('bg-next');
    }
  });

  $('.fcp-section.bg-enabled, .fcp-section.with-image, .fcp-section.image-section.v1, .fcp-section.video-section.v1').each(function () {
    if($(this).next().hasClass('cta-section')) {
      $(this).addClass('cta-next');
    }
  });
}

function getTorqueForRPM(rpm,jsonMotorData) {
  let roundRPM = Math.round(rpm/100)*100;
  for (let i = 0; i < jsonMotorData.length; i++) {
    if (Number(jsonMotorData[i][0]) == roundRPM) {
      return Number(jsonMotorData[i][1])
    }
  }
}

function checkMapWidth() {
  let addressWrapperWidth = $('#map-address-wrp').width();
  let mapWrapperWidth = $('.map-wrp').width();

  if (mapWrapperWidth < addressWrapperWidth) {
    return true;
  } else {
    return false;
  }
}

// Cookie Policy Popup
function getCookie(cname) {
  var name = cname + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}
// Cookie Policy Popup

// New Calculator
function newCalculator() {
  // Calculations on Initial Load
  let selectedCalculation = $('#new-motor-calculator .select-calculation').val();
  $('#new-motor-calculator .calculator-body-wrp .calculator-item').each(function () {
    $(this).slideUp();
  });
  $(`#new-motor-calculator .calculator-body-wrp .calculator-item.${selectedCalculation}`).slideDown();
  // Calculations on Initial Load

  // Calculations on Change
  $('#new-motor-calculator .select-calculation').on('change', function() {
    $('#new-motor-calculator .calculator-body-wrp .calculator-item').each(function () {
      $(this).slideUp();
    });
    $(`#new-motor-calculator .calculator-body-wrp .calculator-item.${this.value}`).slideDown();
  });

  speedCalculator();
  torqueCalculator();
  powerCalculator();
  // Calculations on Change
}

function speedCalculator() {
  $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input').on('input', function() {
    if ($('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#power').val() || $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#torque').val()) {
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#flow-rate').prop('disabled',true);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#volumetric-efficiency').prop('disabled',true);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#displacement').prop('disabled',true);

      if ($('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#power').val() && $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#torque').val()) {
        let torqueValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#torque').val();
        let powerValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#power').val();
        let answer = (powerValue/torqueValue)/(2*Math.PI);
        $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator #answer-speed').text(answer.toFixed(3));
      } else {
        $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator #answer-speed').text('');
      }

    } else {
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#flow-rate').prop('disabled',false);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#volumetric-efficiency').prop('disabled',false);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#displacement').prop('disabled',false);
    }

    if ($('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#flow-rate').val() || $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#volumetric-efficiency').val() || $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#displacement').val()) {
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#power').prop('disabled',true);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#torque').prop('disabled',true);

      if ($('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#flow-rate').val() && $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#volumetric-efficiency').val() && $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#displacement').val()) {
        let flowRate = $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#flow-rate').val();
        let volumetricEfficiency = $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#volumetric-efficiency').val();
        let displacement = $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#displacement').val();
        let answer = (flowRate*volumetricEfficiency/100)/displacement;
        $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator #answer-speed').text(answer.toFixed(3));
      } else {
        $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator #answer-speed').text('');
      }

    } else {
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#power').prop('disabled',false);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#torque').prop('disabled',false);
    }
  });
}

function torqueCalculator() {
  $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input').on('input', function() {
    if ($('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#power').val() || $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#speed').val()) {
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#mechanical-efficiency').prop('disabled',true);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#displacement').prop('disabled',true);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#pressure').prop('disabled',true);

      if ($('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#power').val() && $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#speed').val()) {
        let powerValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#power').val();
        let speedValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#speed').val();
        let answer = (powerValue/(speedValue*2*Math.PI));
        $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator #answer-torque').text(answer.toFixed(3));
      } else {
        $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator #answer-torque').text('');
      }

    } else {
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#mechanical-efficiency').prop('disabled',false);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#displacement').prop('disabled',false);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#pressure').prop('disabled',false);
    }

    if ($('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#mechanical-efficiency').val() || $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#displacement').val() || $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#pressure').val()) {
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#power').prop('disabled',true);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#speed').prop('disabled',true);

      if ($('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#mechanical-efficiency').val() && $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#displacement').val() && $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#pressure').val()) {
        let mechanicalEfficiency = $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#mechanical-efficiency').val();
        let displacement = $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#displacement').val();
        let pressure = $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#pressure').val();
        let answer = ((mechanicalEfficiency/100)*pressure*displacement)/(2*Math.PI);
        $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator #answer-torque').text(answer.toFixed(3));
      } else {
        $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator #answer-torque').text('');
      }

    } else {
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#power').prop('disabled',false);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#speed').prop('disabled',false);
    }
  });
}

function powerCalculator() {
  $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input').on('input', function() {
    if ($('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#torque').val() || $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#speed').val()) {
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#mechanical-efficiency').prop('disabled',true);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#volumetric-efficiency').prop('disabled',true);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#flow-rate').prop('disabled',true);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#pressure').prop('disabled',true);

      if ($('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#torque').val() && $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#speed').val()) {
        let torque = $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#torque').val();
        let speed = $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#speed').val();

        let answer = torque*speed*2*Math.PI;
        $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator #answer-power').text(answer.toFixed(3));
      } else {
        $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator #answer-power').text('');
      }

    } else {
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#mechanical-efficiency').prop('disabled',false);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#volumetric-efficiency').prop('disabled',false);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#flow-rate').prop('disabled',false);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#pressure').prop('disabled',false);
    }

    if ($('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#mechanical-efficiency').val() || $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#volumetric-efficiency').val() || $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#flow-rate').val() || $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#pressure').val()) {
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#torque').prop('disabled',true);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#speed').prop('disabled',true);

      if ($('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#mechanical-efficiency').val() && $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#volumetric-efficiency').val() && $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#flow-rate').val() && $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#pressure').val()) {
        let mechanicalEfficiency = $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#mechanical-efficiency').val();
        let volumetricEfficiency = $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#volumetric-efficiency').val();
        let flowRate = $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#flow-rate').val();
        let pressure = $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#pressure').val();
        let answer = pressure*flowRate*(volumetricEfficiency/100)*(mechanicalEfficiency/100);
        $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator #answer-power').text(answer.toFixed(3));
      } else {
        $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator #answer-power').text('');
      }

    } else {
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#torque').prop('disabled',false);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#speed').prop('disabled',false);
    }
  });
}
// New Calculator